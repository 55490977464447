import { Component, Input, OnChanges } from '@angular/core';
import { FormProvider } from '../form-provider';

import { FieldMarketplace } from '@topseller/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'ts-marketplace-fields',
  templateUrl: './marketplace-fields.component.html',
  styleUrls: ['./marketplace-fields.component.scss'],
})
export class TsMarketplaceFieldsComponent implements OnChanges {
  @Input() public field!: FieldMarketplace;

  @Input()
  public index = 0;

  @Input()
  public isEdit!: boolean;

  @Input()
  public isNew!: boolean;

  @Input()
  public error!: Array<string> | undefined;

  public form: FormGroup = new FormGroup({});

  constructor(private formProvider: FormProvider) {}

  public ngOnChanges(): void {
    this.form = (
      this.formProvider?.getForm().get('marketplaceRequisites') as FormArray
    ).controls[this.index] as FormGroup;
  }
}
