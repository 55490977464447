import { Routes } from '@angular/router';

import { environment } from '../../../environments/environment';
import { AuthGuard } from "../services/auth.guard";
import { ROLES, RolesGuard } from "@topseller/core";

export const APPS_ROUTES: Routes = [
  {
    path: 'wb-ads',
    loadChildren: () =>
      import('../../../wb-ads/wb-ads.module').then((m) => m.WbAdsModule),
  },
  {
    path: 'connect',
    loadChildren: () =>
      import('../../../connect/connect.module').then((m) => m.ConnectModule),
    canActivate: [RolesGuard],
    data: {
      externalUrl: environment.connectUrl,
      appName: 'application.connect',
      requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW
    },
  },
  {
    path: 'pim',
    loadChildren: () =>
      import('../../../connect/connect.module').then((m) => m.ConnectModule),
    canActivate: [RolesGuard],
    data: {
      externalUrl: environment.pimUrl,
      appName: 'application.pim',
      requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW
    },
  },
  {
    path: 'journal',
    loadChildren: () =>
      import('../../../connect/connect.module').then((m) => m.ConnectModule),
    canActivate: [RolesGuard],
    data: {
      externalUrl: environment.journalUrl,
      appName: 'application.journal',
    }
  },
  {
    path: 'hub',
    loadChildren: () =>
      import('../../../../../../libs/hub/src/lib/hub.module').then(
        (m) => m.HubModule
      ),
  },
  {
    path: 'ai',
    loadChildren: () => import('../../../ai/ai.module').then((m) => m.AiModule),
  },
  {
    path: 'dropship',
    loadChildren: () =>
      import('../../../dropship/dropship.module').then((m) => m.DropshipModule),
  },
  {
    path: 'repricer',
    loadChildren: () =>
      import('../../../../../../libs/repricer/src/lib/repricer.module').then(
        (m) => m.RepricerModule
      ),
  },
  {
    path: 'topsellerid',
    loadChildren: () =>
      import('../../../../../../libs/identity/src/lib/identity.module').then(
        (m) => m.IdentityModule
      ),
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('../../../../../../libs/analytics/src/lib/analytics.module').then(
        (m) => m.AnalyticsModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../pages/main-page/main-page.module').then((m) => m.MainPageModule)
  }
];
