<form [formGroup]="form" (ngSubmit)="save()" class="d-flex flex-column gap-3 ts-page__content">
  <ts-handbook-item-control-bar [handbooksSettings]="'Настройки организации'" [editItemRole]="editItemRole">
    <app-toggle-switch
      formControlName="isVatPayer"
      label="Плательщик НДС"
      slot="bar-content"
    />
  </ts-handbook-item-control-bar>

  <ts-form-controls-card label="Общее" [isExpandable]="false">
    <div slot="card-actions">
      <ts-role-access-wrapper [requiredRole]="deleteItemRole">
        <button
          *ngIf="showHeaderBtns$ | async" type="button"
          class="ts-btn error ts-btn-bezeled ts-btn-md"
          (click)="deleteOrganization()"
        >
          <span class="ts-icon ts-icon-actions-Trash"></span>
          <span class="">Удалить организацию</span>
        </button>
      </ts-role-access-wrapper>
    </div>

    <div class="form-controls-card__content">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <div class="ts-general-controls-grid">
          <ts-select
            [hasCleaner]="false"
            formControlName="legalForm"
            label="Тип контрагента"
            [stringify]="legalFormStringify"
          >
            <button
              [tsSelectOption]="item"
              class="dropdown-item"
              *ngFor="let item of legalFormObject"
            >
              {{ item | legalForm }}
            </button>
          </ts-select>

          <app-title
            formControlName="fullName"
            title="Полное наименование"
            placeholder="Полное наименование компании"
          />

          <div class="company-info__fill-wrap">
            <app-title
              formControlName="inn"
              title="Заполнить по ИНН"
              placeholder="1234567890"
              [maskTemplate]="onlyNumberTemplate"
              trailingIcon="ts-icon-document-edit"
              [trailingIconClickable]="true"
              (trailingIconClick)="fillByInn(form)"
            />
            <span
              class="input-error"
              *ngIf="inn && inn.touched && inn.dirty && inn.invalid"
            >некоректный ИНН</span>
          </div>

          <app-title
            formControlName="phone"
            title="Телефон"
            placeholder="+7(999)999-99-99"
          />

          <app-title
            formControlName="email"
            title="E-mail"
            placeholder="example@topseller.ru"
          />

          <app-title
            formControlName="legalAddress"
            title="Юридический адрес"
            placeholder=" Москва, ..."
          />

          <app-title
            formControlName="actualAddress"
            title="Фактический адрес"
            placeholder="Москва, ..."
          />

          <app-title
            formControlName="kpp"
            title="КПП"
            placeholder="123456789"
            [maskTemplate]="onlyNumberTemplate"
          />

          <app-title
            formControlName="okpo"
            title="ОКПО"
            placeholder="12345678901234"
            [maskTemplate]="onlyNumberTemplate"
          />

          <app-title
            formControlName="ogrn"
            title="ОГРН"
            placeholder="Н-р: 12345678901234"
            [maskTemplate]="onlyNumberTemplate"
          />

          <ts-select
            *ngIf="isVatPayer"
            label="НДС"
            formControlName="vatType"
            [identityMatcher]="recordIdentityMatcher"
            [stringify]="recordStringify" [hasCleaner]="false"
          >
            <button type="button" *ngFor="let vat of vatList" class="dropdown-item" [tsSelectOption]="vat">
              {{ vat.name }}
            </button>
          </ts-select>

          <app-title formControlName="head" title="Руководитель" placeholder="ФИО"/>

          <ts-add-company-image formControlName="signature" label="Подпись руководителя" [id]="itemId$|async"/>



          <app-title formControlName="bookkeeper" title="Главный бухгалтер" placeholder="ФИО"/>

          <ts-add-company-image formControlName="bookkeeperSignature" label="Подпись главного бухгалтера"/>

          <ts-add-company-image formControlName="stamp" label="Печать"/>

          <ts-add-company-image formControlName="logo" label="Логотип"/>

        </div>
      </ts-role-access-wrapper>
    </div>
  </ts-form-controls-card>

  <ts-form-controls-card label="Банковский счет" [isExpandable]="true">
    <div slot="card-actions">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <button
          type="button"
          class="ts-btn secondary ts-btn-filled ts-btn-md ts-btn-icon ts-controls-bar-item"
          (click)="settlementService.addAccount()"
        >
          <span class="ts-icon ts-icon-actions-Add"></span>
          Счет
        </button>
      </ts-role-access-wrapper>
    </div>

    <div class="form-controls-card__content">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <ts-handbook-edit-bank-account
          [bankAccountGroups]="accountControls"
          (changed)="settlementService.settlementToggleHandler($event)"
          (deleteAccount)="settlementService.removeAccount($event)"
          (fillByBik)="settlementService.fillByBik($event)"
        />
      </ts-role-access-wrapper>
    </div>
  </ts-form-controls-card>
</form>

<!-- Confirm Dialogs -->

<app-confirm-dialog
  *ngIf="showDialog"
  (selection)="onDeactivateSelection($event)"
></app-confirm-dialog>

