import { ChangeDetectorRef, Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';

import { HubDocument, HubEntity, Product, ProductStock, ProductStockService, Vat } from '../data';
import { HubState, selectMetadata, selectVat } from '../store';

import {
  CatalogsDataService,
  DocumentItemsTableTotalService,
  DocumentUntrashService,
  ItemDataService,
  TsEntityService
} from './services';
import { Paginated, Warehouse } from '@topseller/core';
import { Location } from "@angular/common";
import { OrderProduct } from "../data/model/order-product";
import { TableHeader } from "@topseller/ui";
import {
  tradeDocumentProductsBuyerTableHeaders,
  tradeDocumentProductsSupplierTableHeaders,
  tradeDocumentProductsTableHeaders,
  tradeDocumentProductsWithVatTableHeaders
} from "../constants";
import { DocumentWithProductsEditBase } from "./document-with-products-edit-base";
import { calculatePercent, calculateVat } from './utils/calculate-vat';
import { TradeDocumentProductLineForm } from "./interfaces";
import { createExpenseFormGroup } from "./utils/form-groups";
import { getDefaultProductPrice } from "./helpers";


@Directive()
export abstract class TradeDocumentEditBase<T extends HubDocument> extends DocumentWithProductsEditBase<T> implements OnInit {
  public defaultValues: any;

  @Input() hasExpenses = false;

  public productTableHeaders$: Observable<TableHeader[]> = of([]);

  calculatePercent = calculatePercent;
  protected productLineSubscriptions = new WeakMap<AbstractControl, Subscription[]>();

  protected constructor(override entityName: HubEntity,
                        catalogsDataService: CatalogsDataService,
                        store: Store<HubState>,
                        activatedRoute: ActivatedRoute,
                        productStockService: ProductStockService,
                        itemService: DocumentUntrashService<T>,
                        itemDataService: ItemDataService<T>,
                        toastrService: ToastrService,
                        dialog: MatDialog,
                        router: Router,
                        location: Location,
                        public entityService: TsEntityService,
                        changeDetectorRef: ChangeDetectorRef,
                        documentProductsTotalService: DocumentItemsTableTotalService<any>) {
    super(
      catalogsDataService,
      store,
      activatedRoute,
      productStockService,
      itemService,
      itemDataService,
      toastrService,
      dialog,
      router,
      location,
      changeDetectorRef,
      documentProductsTotalService);

    store
      .select(selectVat)
      .pipe(filter((vat) => !!vat), take(1))
      .subscribe((vat: Vat[]) => {
        this.vatList = vat;
      });

    store
      .select(selectMetadata)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.defaultValues = res.defaultValues!;
        }
      });
  }

  public get priceIncludesVat(): FormControl<boolean> | null {
    return this.form.get('isPriceIncludesVat') as FormControl<boolean>;
  }

  protected get isVat(): FormControl<boolean> {
    return this.form.get('isVat') as FormControl<boolean>;
  }

  protected get productExpensesGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(''),
      source: new FormControl(null),
      expenseSpreadType: new FormControl(''),
      expensedAt: new FormControl(new Date(), [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.setupProductTableHeader();
  }

  public override deleteProductLines(): void {
    for (let i = this.selectedProducts.length - 1; i >= 0; i--) {
      const control = this.products.at(this.selectedProducts[i]);
      const subscriptions = this.productLineSubscriptions.get(control);
      subscriptions?.forEach((subscription) => subscription.unsubscribe());

      this.products.removeAt(this.selectedProducts[i]);
    }
    this.selectedProducts = [];
    this.form.markAsDirty();
  }

  public calculateVat(value: {
    price: number; quantity: number; vatType: Vat;
  }): number {
    const {price, quantity, vatType} = value;
    if (!this.isVat.value || !vatType?.vatValue) {
      return 0;
    }
    return calculateVat(price, quantity, vatType, this.priceIncludesVat?.value ?? false);
  }

  public setProductsToTable() {
    this.products.clear({emitEvent: false});
    const {products} = this.item;
    products?.forEach((product: OrderProduct) => {
      const productGroup = this.productGroup;

      // Проверяем, нужно ли добавлять расходы к продукту
      if (this.hasExpenses) {
        const expensesArray = new FormArray<FormGroup>([]);
        product.expenses?.forEach(expense => {
          const expenseGroup = createExpenseFormGroup();
          expenseGroup.patchValue(expense);
          expensesArray.push(expenseGroup);
        });

        // Устанавливаем expenses только если hasExpenses === true
        productGroup.setControl('expenses', expensesArray);
      }

      productGroup.patchValue({
        ...product,
      });
      productGroup
        .get('product')
        ?.valueChanges.pipe(takeUntil(this.destroy$), filter((value: Product | null) => !!value), switchMap((value: Product | null) => this.productStockService.postAppProductstockProducts((this.item.warehouse as Warehouse).id, {ids: [value?.id]})))
        .subscribe(({items}: Paginated<ProductStock>) => {
          (productGroup as FormGroup).controls['product'].value.stocks = items[0].stock;
          (productGroup as FormGroup).controls['product'].value.reserved = items[0].reserved;
        });
      this.products.push(productGroup);
      this.addLineSubscriptions(productGroup);
    });

    if (this.isTrashed) {
      this.form.disable();
    }
  }

  protected override setupTotals() {
    this.totals$ = combineLatest([this.products.valueChanges.pipe(startWith(this.products.value)), this.priceIncludesVat!.valueChanges.pipe(startWith(this.priceIncludesVat!.value)), this.isVat.valueChanges.pipe(startWith(this.isVat.value)),]).pipe(map(([products, vatIncluded, isVat]) => {
      return this.documentProductsTotalService.getTotals({items: products, vatIncluded, isVat});
    }),);
  }

  protected override initItem(): void {
    this.item = this.activatedRoute.snapshot.data["item"] || {};
  }

  protected override updateProductFormWithStocks(items: ProductStock[]) {
    this.products.controls.forEach((item, index) => {
      (item as FormGroup).controls['product'].value.stocks = items.length ? items[index].stock : 0;
      (item as FormGroup).controls['product'].value.reserved = items.length ? items[index].reserved : 0;
    });
  }

  protected addProduct(product: Product, stockArray?: Array<ProductStock>): void {
    const newGroup = this.productGroup;
    this.productLinePlaceholder.setValue(null, {
      onlySelf: true, emitEvent: false,
    });
    // Добавим строку
    this.isTableIdle = false;
    newGroup.patchValue({
      price: getDefaultProductPrice(product, this.entityName),
      product: {
        ...product,
        stocks: stockArray ? stockArray[0].stock : 0,
        reserved: stockArray ? stockArray[0].reserved : 0,
      },
    }, {
      onlySelf: true, emitEvent: false,
    });

    newGroup
      .get('product')
      ?.valueChanges.pipe(takeUntil(this.destroy$), filter((value: Product | null) => !!value), switchMap((value: Product | null) => this.productStockService.postAppProductstockProducts((this.item.warehouse as Warehouse).id, {ids: [value?.id]})))
      .subscribe(({items}: Paginated<ProductStock>) => {
        (newGroup as FormGroup).controls['product'].value.stocks = items.length ? items[0].stock : 0;
        (newGroup as FormGroup).controls['product'].value.reserved = items.length ? items[0].reserved : 0;
      });

    this.products.push(newGroup);
    this.addLineSubscriptions(newGroup);
    this.search.setValue('');
    this.form.markAsDirty();
  }

  protected addLineSubscriptions(productGroup: FormGroup<TradeDocumentProductLineForm>): void {
    const productSubscription: Subscription = productGroup
      .get('product')!
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((product: Product | null) => {
        const vatType = this.vatList?.find(({id}: Vat) => id === product?.vat?.toString());
        productGroup.patchValue({vatType});
      });

    this.productLineSubscriptions.set(productGroup, [productSubscription]);
  }

  protected setupProductTableHeader() {
    this.productTableHeaders$ = this.isVat.valueChanges.pipe(
      startWith(this.isVat.value),
      takeUntil(this.destroy$),
      map((isVat: boolean) => {
          let headers = [];
          if (isVat) {
            headers = this.getHeadersWithVat();
          } else {
            headers = [...tradeDocumentProductsTableHeaders]
          }

          if (this.entityName === HubEntity.PURCHASE) {
            headers.splice(5, 2, ...tradeDocumentProductsSupplierTableHeaders);
          } else if (this.entityName === HubEntity.ORDER) {
            headers.splice(5, 2, ...tradeDocumentProductsBuyerTableHeaders);
          } else {
            headers.splice(5, 2);
          }

          if (!this.hasExpenses) {
            headers = headers.filter(x => x.key != 'expensesSum');
          }
          return headers;
        }
      ),
      shareReplay(1)
    );
  }

  private getHeadersWithVat() {
    const firstPart = tradeDocumentProductsTableHeaders.slice(0, 8);
    const secondPart = tradeDocumentProductsWithVatTableHeaders;
    const thirdPart = tradeDocumentProductsTableHeaders.slice(8);

    return firstPart.concat(secondPart, thirdPart);
  }

}
