<form [formGroup]="form" (ngSubmit)="save()" class="d-flex flex-column gap-3 ts-page__content">
  <ts-handbook-item-control-bar [handbooksSettings]="'Статья доходов/расходов'" [editItemRole]="editItemRole"/>

  <ts-form-controls-card label="Общее" [isExpandable]="false">
    <div slot="card-actions">
      <ts-role-access-wrapper [requiredRole]="deleteItemRole">
        <button *ngIf="showHeaderBtns$ | async" type="button" class="ts-btn error ts-btn-bezeled ts-btn-md"
                (click)="deleteSource()">
          <span class="ts-icon ts-icon-actions-Trash"></span>
          <span class="">Удалить статью</span>
        </button>
      </ts-role-access-wrapper>
    </div>

    <div class="form-controls-card__content ">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <div class="ts-general-controls-grid">
          <ts-select label="Тип" formControlName="isIncome" [stringify]="recordBooleanStringify" [hasCleaner]="false">
            <div>
              <button class="dropdown-item" [tsSelectOption]="true">
                Статья дохода
              </button>
              <button class="dropdown-item" [tsSelectOption]="false">
                Статья расхода
              </button>
            </div>
          </ts-select>

          <div>
            <ts-select label="Группа" [identityMatcher]="recordIdentityMatcher" [stringify]="recordStringify"
                       formControlName="sourceGroup">
              <ts-entity-list [queryFn]="sourceGroupQueryFn"></ts-entity-list>
            </ts-select>
          </div>
        </div>

        <div class="col-9 col-xl-12">
          <app-title formControlName="note" title="Примечание" [multirow]="true"/>
        </div>
      </ts-role-access-wrapper>
    </div>
  </ts-form-controls-card>
</form>

<!-- Confirm Dialogs -->

<app-confirm-dialog *ngIf="showDialog" (selection)="onDeactivateSelection($event)"></app-confirm-dialog>

