import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective } from 'ngx-mask';
import { FileDropComponent } from './components/filedrop/file-drop.component';

import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TsEntityListModule } from '@topseller/common/entity-list';
import { TsPaginatorModule } from '@topseller/common/paginator';
import { TsSearchModule } from '@topseller/common/search';
import { TableImageModule } from '@topseller/common/table-image';
import { InputNumberModule } from '@topseller/ui/input-number';
import { TsSelectModule } from '@topseller/ui/select';

import { ActiveZoneModule } from '@topseller/ui/active-zone';
import { BaseInputModule } from '@topseller/ui/base-input';
import { CalendarModule } from '@topseller/ui/calendar';
import { TsDropdownModule } from '@topseller/ui/dropdown';
import { InputDateTimeModule } from '@topseller/ui/input-date-time';
import { TsInputTextModule } from '@topseller/ui/input-text';
import { TsSelectOptionModule } from '@topseller/ui/select-option';
import { TimePickerModule } from '@topseller/ui/time-picker';
import { ToggleSwitchModule } from '@topseller/ui/toggle-switch';

import { PipesModule } from './pipes/pipes.module';
import { TextAreaComponent } from './components/text-area/text-area.component';

import { DotLoaderComponent } from '@topseller/ui/dot-loader';
import { AttentionTooltipComponent } from './components/attention-tooltip/attention-tooltip.component';
import { ButtonImageComponent } from './components/button-image/button-image.component';
import { DropdownV2Component } from './components/dropdown-v2/dropdown-v2.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LabelComponent } from './components/label/label.component';
import { NavTreeItemComponent } from './components/nav-tree/menu/nav-tree-item.component';
import { NavTreeComponent } from './components/nav-tree/nav-tree.component';

import { CatalogTableComponent } from './components/catalog-table/catalog-table.component';
import { ChangesHistoryComponent } from './components/changes-history/changes-history.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FilesBlockComponent } from './components/files-block/files-block.component';
import { LabelInputComponent } from './components/label-input/label-input.component';

import { TsEntityCommentModule } from './components/comments-block/entity-comment.module';
import { ErrorTextComponent } from './components/error-text/error-text.component';
import { ExtraFieldsModule } from './components/extra-fields/extra-fields.module';

import { TsHintModule } from '@topseller/ui/hint';
import { FilterApiService } from '../data/api';
import { AnchorNavComponent } from './components/anchor-nav/anchor-nav.component';
import { DropdownButtonModule } from './components/dropdown-button/dropdown-button.module';
import { FileDropDirective } from './components/filedrop/file-drop.directive';
import { ProductTypeImageComponent } from './components/product-type-image/product-type-image.component';
import { StatusSelectorModule } from './components/status-selector/status-selector.module';
import { ScrollAnchorDirective } from './directives/anchor-scroll/scroll-anchor.directive';
import { ScrollManagerDirective } from './directives/anchor-scroll/scroll-manager.directive';
import { ScrollSectionDirective } from './directives/anchor-scroll/scroll-section.directive';
import { StatusColorizerDirective, TsResizeDirective } from './directives';
import { ProductCreationTabComponent } from './components/product-creation-tub/nav-product-creation-tab.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ProductListItemComponent } from './components/product-list-item';
import { SidebarModule } from '@topseller/common/sidebar';
import { InputDateModule } from '@topseller/ui/input-date';
import { InputDateRangeSelectorModule } from '@topseller/ui/input-date-range-selector';
import {
  SettingsButtonComponent,
  TableHeaderCell2Component,
  TsCommonModule,
} from '@topseller/common';
import { TsDrawerModule } from '@topseller/ui/drawer';
import { TsScrollModule } from '@topseller/ui/scroll';
import { ItemsTableComponent } from '@topseller/common/items-table';
import { FileListItemComponent } from './components/files-block/file-list-item/file-list-item.component';
import { DocumentEditHeaderComponent } from './components/item-edit-header/document-edit-header.component';
import { ControlErrorModule } from '@topseller/common/control-error/control-error.module';
import { FocusableDirective } from '@topseller/ui/directives/focusable.directive';
import { ProductDimensionsCellComponent } from './table/product-dimensions-cell/product-dimensions-cell.component';
import { ProductSkusCellComponent } from './table/product-skus-cell/product-skus-cell.component';
import { TableHeaderCellComponent } from '@topseller/ui/tables';
import { ViewEditModeSwitchComponent } from '@topseller/ui/view-edit-mode-switch/view-edit-mode-switch.component';
import { ViewModeDirective } from '@topseller/ui/view-edit-mode-switch/view-mode.directive';
import { TradeDocumentProductsTableComponent } from './components/trade-document-products-table/trade-document-products-table.component';
import { TradeDocumentTableEmptyRowComponent } from './components/trade-document-products-table/trade-document-table-empty-row/trade-document-table-empty-row.component';
import { ProductNameCellComponent } from './table/product-name-cell/product-name-cell.component';
import { TsTableModule } from '@topseller/common/table';
import { ModifiedByCellComponent } from '@topseller/common/table/modified-by-cell/modified-by-cell.component';
import { AttachedFileComponent } from '@topseller/common/attached-file/attached-file.component';
import { BytesToMegabytesPipe } from '@topseller/ui/pipes/bytes-to-megabytes/bytes-to-megabytes.pipe';
import { ItemEditCommentsFilesComponent } from './components/item-edit-comments-files/item-edit-comments-files.component';
import { FormControlsCardComponent } from '@topseller/common/form-controls-card/form-controls-card.component';
import { ToggleButtonsGroupComponent } from '@topseller/ui/toggle-buttons-group/toggle-buttons-group.component';
import { DropUploadContainerComponent } from '@topseller/ui/drop-upload-container/drop-upload-container.component';
import { ItemControlsBarComponent } from './components/item-controls-bar/item-controls-bar.component';
import { ActionsDropdownComponent } from '@topseller/ui/actions-dropdown/actions-dropdown.component';
import { ItemProductsTableSummaryComponent } from './components/item-products-table-summary/item-products-table-summary.component';
import { HubTableEmptyComponent } from './components/hub-table-empty/hub-table-empty.component';
import { DocumentExpensesTableComponent } from './components/document-expenses-table/document-expenses-table.component';
import { ProductExpensesModalComponent } from './components/product-expenses-modal/product-expenses-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentExpensesTableEmptyRowComponent } from './components/document-expenses-table/document-expenses-table-empty-row/document-expenses-table-empty-row.component';
import { SourceListItemComponent } from './components/document-expenses-table/source-list-item/source-list-item.component';
import { TsRemoveButtonModule } from '@topseller/common/remove-button/ts-remove-button.module';
import { FilterControlComponent } from '@topseller/common/table/filter-control/filter-control.component';
import { RoleAccessWrapperComponent } from '@topseller/ui/role-access-wrapper';
import { SelectionOfGoodsFromCatalogComponent } from './components/selection-of-goods-from-catalog/selection-of-goods-from-catalog.component';
import { TsRefreshModule } from '@topseller/common/refresh';
import { ProductModule } from '../pages/products-services';
import { TsTablePaginationModule } from '@topseller/common/table-pagination';
import { AppliedFiltersRowComponent } from './components/applied-filters-row';
import { ProductPriceGroupCellComponent } from './table';
import { ProductsCatalogComponent } from './components/selection-of-goods-from-catalog/products-catalog/products-catalog.component';
import { SelectedProductsComponent } from './components/selection-of-goods-from-catalog/selected-products/selected-products.component';
import { LoaderAnimatedModule } from '@topseller/ui/loader-animated';
import { TsTableRowDirective } from '@topseller/common/items-table/table-row.directive';
import { InputCounterComponent } from '@topseller/ui/input-counter/input-counter.component';
import { CatalogModalWrapperComponent } from './components/selection-of-goods-from-catalog/catalog-modal-wrapper/catalog-modal-wrapper.component';
import { ProductsCatalogTableComponent } from './components/selection-of-goods-from-catalog/products-catalog/products-catalog-table/products-catalog-table.component';
import { ProductPriceSelectorCellComponent } from "./components/product-price-selector-cell";
import { ProductPriceTableHeaderComponent } from "./components/product-price-table-header";
import { PrintDocsSelectorComponent } from "./components/print-docs-selector";

const HUB_MODULES = [
  CalendarModule,
  InputNumberModule,
  TsDropdownModule,
  TimePickerModule,
  TsSelectModule,
  TsDrawerModule,
  InputDateTimeModule,
  TsEntityListModule,
  TsInputTextModule,
  ExtraFieldsModule,
  DropdownButtonModule,
  StatusSelectorModule,
  ToggleSwitchModule,
  TsSelectOptionModule,
  TsPaginatorModule,
  TsSearchModule,
  TableImageModule,
  PipesModule,
];

const HUB_DIRECTIVES = [
  FileDropDirective,
  ScrollManagerDirective,
  ScrollAnchorDirective,
  ScrollSectionDirective,
  StatusColorizerDirective,
];

const HUB_COMPONENTS = [
  ProductListItemComponent,
  DropdownV2Component,
  AttentionTooltipComponent,
  ButtonImageComponent,
  DropdownComponent,
  LabelComponent,
  ProductCreationTabComponent,
  NavTreeComponent,
  NavTreeItemComponent,
  ConfirmDialogComponent,
  LabelInputComponent,
  FilesBlockComponent,
  ChangesHistoryComponent,
  CatalogTableComponent,
  LabelInputComponent,
  ErrorTextComponent,
  TextAreaComponent,
  FileDropComponent,
  AnchorNavComponent,
  ProductTypeImageComponent,
  ItemEditCommentsFilesComponent,
  ItemControlsBarComponent,
  ItemProductsTableSummaryComponent,
  HubTableEmptyComponent,
  DocumentEditHeaderComponent,
  TradeDocumentProductsTableComponent,
  TradeDocumentTableEmptyRowComponent,
  DocumentExpensesTableComponent,
  ProductExpensesModalComponent,
  DocumentExpensesTableEmptyRowComponent,
  SourceListItemComponent,
  SelectionOfGoodsFromCatalogComponent,
];

@NgModule({
    imports: [
        ActiveZoneModule,
        BaseInputModule,
        CommonModule,
        DotLoaderComponent,
        FormsModule,
        NgbDropdownModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        NativeDateModule,
        TsEntityCommentModule,
        MatCardModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgxMaskDirective,
        ...HUB_MODULES,
        TsHintModule,
        RouterLink,
        RouterLinkActive,
        SidebarModule,
        InputDateModule,
        InputDateRangeSelectorModule,
        TsCommonModule,
        TsScrollModule,
        ItemsTableComponent,
        ProductNameCellComponent,
        TsTableModule,
        ModifiedByCellComponent,
        AttachedFileComponent,
        BytesToMegabytesPipe,
        NgOptimizedImage,
        FormControlsCardComponent,
        ToggleButtonsGroupComponent,
        DropUploadContainerComponent,
        ActionsDropdownComponent,
        FileListItemComponent,
        ControlErrorModule,
        FocusableDirective,
        ProductDimensionsCellComponent,
        ProductSkusCellComponent,
        TableHeaderCellComponent,
        ViewEditModeSwitchComponent,
        ViewModeDirective,
        MatDialogModule,
        TsRemoveButtonModule,
        FilterControlComponent,
        RoleAccessWrapperComponent,
        TsRefreshModule,
        SettingsButtonComponent,
        TsTablePaginationModule,
        AppliedFiltersRowComponent,
        ProductPriceGroupCellComponent,
        TsResizeDirective,
        LoaderAnimatedModule,
        TableHeaderCell2Component,
        TsTableRowDirective,
        InputCounterComponent,
        RouterOutlet,
        ProductPriceSelectorCellComponent,
        ProductPriceTableHeaderComponent,
        PrintDocsSelectorComponent,
        // ProductModule,
    ],
  declarations: [
    ...HUB_DIRECTIVES,
    ...HUB_COMPONENTS,
    ProductsCatalogComponent,
    SelectedProductsComponent,
    CatalogModalWrapperComponent,
    ProductsCatalogTableComponent,
  ],
  exports: [...HUB_DIRECTIVES, ...HUB_COMPONENTS, HUB_MODULES],
  providers: [FormGroupDirective, FilterApiService],
})
export class HubCommonModule {}
